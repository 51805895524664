
import {PostApi } from '@/api'


export async function listPhonePrefixOption() {
    return await PostApi.listPhonePrefixOption()

}


export async function sendLoginMessageCode(data) {
    return await PostApi.sendLoginMessageCode(data)
}

export async function login(data) {
    return await PostApi.saleCutomerLogin(data)
}


