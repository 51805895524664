<template>
    <div :class="[!isCountryFocus ? 'login-height' : 'focus-height', 'input-animate']">
        <!-- <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"> -->

        <div class="login-container">
            <div class="login-header">
                <h2>欢迎使用</h2>
            </div>
            <el-form :model="loginForm" status-icon :rules="rules" ref="loginForm" class="login-form"
                v-loading="loginLoading">
                <el-form-item>
                    <el-select ref="selectRef" v-model="loginForm.countryCode" filterable size='small'
                        popper-class="options-height" style="width: 30%; margin-right: 2%; margin-left: 2%;"
                        @visible-change='visibleChange' @hook:mounted="cancelReadOnly">
                        <el-option v-for="(item, index) in countryList" :key="index" :label="`+${item.value}`"
                            :value="item.value">
                            <span style="float: left;padding: 0 10px;">{{ item.value }}</span>
                            <span style="float: right; color: #8492a6; font-size: 13px;padding: 0 10px;">{{ item.label
                                }}</span>
                        </el-option>
                    </el-select>
                    <el-input v-model="loginForm.phone" placeholder="请输入您的手机号码" style="width: 60%;"></el-input>
                </el-form-item>
                <el-form-item prop="verificationCode">
                    <el-input v-model="loginForm.verificationCode" placeholder="请输入验证码"
                        style="width: 92%; margin-left: 2%;">
                        <el-button :disabled="countSecond != 0" @click="getVerificationCode" slot="append">{{
                            verificateMsg
                        }}</el-button>
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size="medium" @click="handleLogin" class="login-button">登录</el-button>
                </el-form-item>
            </el-form>
        </div>

    </div>
</template>
<script>
import { sendLoginMessageCode, login, listPhonePrefixOption } from '../../api/dandelion';
export default {
    name: 'SaleCustomer',
    components: {
    },
    computed: {
        verificateMsg() {
            return this.countSecond == 0 ? '获取验证码' : `${this.countSecond}秒重新获取`
        },
    },
    data() {
        document.title = '蒲公英推品'
        return {
            loginForm: {
                countryCode: 86,
                phone: '',
                verificationCode: ''
            },
            rules: {
                phone: [
                    { required: true, message: '请输入手机号', trigger: 'blur' },
                    { pattern: /^1[3456789]\d{9}$/, message: '手机号格式错误', trigger: 'blur' }
                ]
            }
            ,
            countryList: [],
            isCountryFocus: false,
            countSecond: 0,
            loginLoading: false
        }
    },
    async created() {
        await this.loadCountry()
    },
    methods: {
        async loadCountry() {
            let res = await listPhonePrefixOption()
            this.countryList = []
            let _list = []
            let list = res.data
            for (let item of list) {
                if (item.children) {
                    for (let child of item.children) {
                        const index = _list.findIndex(p => p.label == child.label)
                        if (index == -1) {
                            _list.push({ label: child.label, value: child.value })
                        }
                    }
                }
            }
            this.countryList = [..._list]
        },
        async getVerificationCode() {
            this.loginLoading = true;
            let params = {
                phone: this.loginForm.phone,
                phonePrefix: this.loginForm.countryCode
            }
            let res = await sendLoginMessageCode(params);
            this.loginLoading = false;
            if (res.code === 200) {
                clearInterval()
                this.$message.success('验证码已发送!');
                this.countSecond = 60
                setInterval(() => {
                    if (this.countSecond == 0) {
                        clearInterval()
                    } else {
                        this.countSecond -= 1
                    }
                }, 1000);
                return
            }
            this.$message.error(res.msg);
        },
        async handleLogin() {
            this.$refs.loginForm.validate(async (valid) => {
                if (!valid) {
                    this.$message.error('登录信息填写有误!');
                    return false;
                }
                let params = {
                    phone: this.loginForm.phone,
                    phonePrefix: this.loginForm.countryCode,
                    code: this.loginForm.verificationCode
                }
                let res = await login(params);
                if (res.code === 200) {
                    if (res.data && res.data.url) {
                        let url = res.data.url
                        window.location.href = url
                    }
                    return
                }
                this.$message.error(res.msg);
            });
        },
        visibleChange(val) {
            this.isCountryFocus = val
            this.cancelReadOnly(val)
        },
        cancelReadOnly(val) {
            this.$nextTick(() => {
                if (!val) {
                    const { selectRef } = this.$refs
                    const input = selectRef.$el.querySelector('.el-input__inner')
                    input.removeAttribute('readonly')
                    this.$refs.selectRef.blur()
                }
            })
        }
    }
}
</script>

<style>
.options-height .el-select-dropdown__wrap {
    max-height: 200px !important;
}
</style>
<style lang='scss' scoped>
body {
    font-family: Arial, sans-serif;
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
}

.container {
    background-color: #f2f3f5;
    width: 100%;
    max-width: 390px;
    height: 100vh;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: auto;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding: 0 !important;
}

.points {
    text-align: center;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
}

.count-tit {
    font-size: 14px;
}

.count-val {
    font-size: 18px;
    font-weight: bold;
}

.tabs {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
    border-bottom: 1px solid #ddd;
}

.tabs div {
    cursor: pointer;
    font-size: 16px;
    padding-bottom: 10px;
}

.tabs .active {
    font-weight: bold;
    border-bottom: 2px solid black;
}

.login-height {
    padding-top: 15vh;
}

.focus-height {
    padding-top: 1vh;
}

.input-animate {
    height: 100vh;
    // transition: padding-top .05s;
    width: 100vw;
    background: #f2f3f5;
}

.records {
    flex: 1;
    overflow-y: auto;
}

.record {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 5px;
    border-bottom: 1px solid #eee;
    font-size: 13px;
    line-height: 26px;
}

.border-top-first {
    border-top: 1px solid #eee;
}

.login-header {
    text-align: center;
    margin-bottom: 20px;
}

.login-header h2 {
    margin: 0;
    font-size: 24px;
}

.login-container {
    background-color: white;
    width: 90%;
    max-width: 390px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin: auto;
}

.login-form {
    width: 100%;
}

.login-button {
    margin: 0 auto;
    display: block;
    width: 60%;
}

.border-arround {
    width: 1px;
    height: auto;
    background: #C0C4CC;
}

.tabs-cus {
    /deep/ .el-tabs__header {
        border-bottom: none;
    }

    /deep/ .el-tabs__nav {
        width: 100%;
        text-align: center;
    }

    /deep/ .el-tabs__item {
        width: 50%;
        text-align: item;
    }
}
</style>